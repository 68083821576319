<script>
import { ChevronDown, CalendarIcon, CloseCircle, DocumentIcon, IconNewTab, StoreLarge } from "@/icons";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapState } from "vuex";
import DateRangeFilter from "@/views/payouts/components/DateRangeFilter.vue";
import OrderDetailsBlock from "@/views/order/components/OrderDetailsBlock";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import SelectFilter from "@/views/payouts/components/SelectFilter.vue";
import Summary from "@/views/payouts/components/Summary.vue";
import Tabs from "@/views/payouts/components/Tabs.vue";
import ViewPayoutDialog from "@/views/payouts/components/ViewPayoutDialog.vue";
import UnpaidPayoutsCheckbox from "@/views/payouts/components/UnpaidPayoutsCheckbox.vue"
import Pagination from '@/components/UI/Pagination.vue'

export default {
  name: "Records",

  data() {
    return {
      filteredStoreOptions: [],
      isOrderFilterVisible: false,
      isPayoutStatusOptionsVisible: false,
      isStoreFilterVisible: false,
      storeSearchTerm: "",
      totalRecords: 0,
    }
  },

  mixins: [payoutsMixin],

  created: async function() {
    if(!this.currentShop) {
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });
    }

    this.filteredStoreOptions = [...this.filteredStoreOptions, ...this.storeFilterOptions];
    this.loadingRecords = true
    this.setDefaultStartAndEndDate();
    this.$store.dispatch("payouts/fetchDashboardStats", this.currentShop.id);
    await this.fetchRecords();
    this.totalRecords = this.records.length
  },

  computed: {
    ...mapState("shop", ["connectedShops"]),
    ...mapState('plan', ['currentActivePlan']),

    storeFilterOptions() {
      return this.connectedShops.map(shop => shop);
    },
  },

  components: {
    CalendarIcon,
    ChevronDown,
    CloseCircle,
    DateRangeFilter,
    DocumentIcon,
    IconNewTab,
    OrderDetailsBlock,
    PageHeader,
    SelectFilter,
    StoreLarge,
    Tabs,
    ViewPayoutDialog,
    Summary,
    UnpaidPayoutsCheckbox,
    Pagination
  },

  methods: {
    async applyStoreFilter(filter) {
      this.isStoreFilterVisible = false;
      this.payoutsFilters.target_store = filter;
      this.selectedRecordStore = filter;
      await this.fetchRecords();
      this.recordsCurrentPage = 1;
    },

    closeFilters() {
      this.isOrderFilterVisible = false;
      this.isStoreFilterVisible = false;
    },

    async applyFilter({ key, value }) {
      this.payoutsFilters[key] = value;
      this.closeFilters();
      await this.fetchRecords();
      this.recordsCurrentPage = 1;
    },

    onClickOutsideStoreFilter() {
      this.isStoreFilterVisible = false;
    },

    onClickOutsideOrderFilter() {
      this.isOrderFilterVisible = false;
    },

    async onClearFiltersHandler() {
      Object.keys(this.payoutsFilters).forEach(key => {
        this.payoutsFilters[key] = "";
      });
      this.setDefaultStartAndEndDate();
      this.payoutsFilters.orders_status = "unpaid";
      this.payoutsFilters.date_range = "Last 30 days";
      this.payoutsFilters.target_store = this.selectedRecordStore.text = "All Stores";
      await this.fetchRecords(1);
    },

    onSearchStoreHandler(event) {
      this.filteredStoreOptions = this.storeFilterOptions.filter(store => store.store_domain.includes(event.target.value));
    },

    swapItems() {
      this.isSearchingStore = true;
      document.querySelector(".search-input").focus();
    },

    paginationHandler(page) {
      this.fetchRecords(page);
    },

    async onDateRangeSelectedHandler(value) {
      const { label } = value;
      this.payoutsFilters.date_range = label;
      this.dateRange.startDate = value.startDate;
      this.dateRange.endDate = value.endDate;
      await this.fetchRecords();
      this.recordsCurrentPage = 1;
    },

    updateCurrentPageHandler(page) {
      this.recordsCurrentPage = page
      this.fetchRecords(this.recordsCurrentPage)
    }
  }
}
</script>

<template>
  <section class="page payouts-page">
    <PayoutsUpgradeDialog />

    <!-- Page Header -->
    <PageHeader pageDescription="Manage Payouts for fulfilled orders">
      <template #title>Manage payouts</template>
    </PageHeader>

    <!-- Summary -->
    <Summary :summary="payoutsDashboard" />

    <!-- Tabs -->
    <Tabs />

    <!-- Filters -->
    <section class="filters-listing">
      <ul class="d-flex pa-0 justify-start">
        <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
          <SelectFilter label="All Stores">
            <template #iconBefore>
              <StoreLarge />
            </template>
            <template #label>
              <div class="label" @click="swapItems">
                <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
                <span v-show="!isStoreFilterVisible">{{ payoutsFilters.target_store.store_domain || payoutsFilters.target_store }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                  <li
                    :class="{ 'active': payoutsFilters.target_store.id === filter.id }"
                    v-show="filteredStoreOptions.length > 0"
                    v-for="filter in filteredStoreOptions"
                    :key="filter.id"
                    @click.stop="applyStoreFilter(filter)">
                      {{ filter.store_domain }}
                  </li>
                  <li class="not-found" key="store-not-found" v-show="filteredStoreOptions.length === 0">Store not found</li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="calendar">
          <SelectFilter>
            <template #iconBefore>
              <CalendarIcon />
            </template>
            <template #label>
              <div class="label">{{ payoutsFilters.date_range || "Select Range"  }}</div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <DateRangeFilter opens="right" @onDateRangeSelected="onDateRangeSelectedHandler" :startDate="String(dateRange.startDate)" :endDate="String(dateRange.endDate)" />
          </SelectFilter>
        </li>

        <li class="right-action-container">
          <button class="btn-icon d-flex align-center clear-filters-btn" @click="onClearFiltersHandler">
            <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
          </button>
        </li>
      </ul>
    </section>

    <div class="d-flex align-center my-4" v-if="bulkUnpaidPayouts.length > 0">
      <h4 class="mr-4" style="font-size: 16px;">{{ bulkUnpaidPayouts.length }} {{ bulkUnpaidPayouts.length > 1 ? 'payouts' : 'payout' }} selected</h4>
      <v-btn elevation="0" class="btn btn-border lg btn-success" @click="markAsPaidBulkHandler">Mark as Paid</v-btn>
    </div>

    <!-- Payable Orders Table -->
    <section class="table fixed-layout">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 3%;"></th>
              <th style="width: 12%;">Date (AEST)</th>
              <th style="width: 7.5%;">Payout ID</th>
              <th style="width: 22.5%;">Store</th>
              <th style="width: 10%">Amount</th>
              <th style="width: 10%;">Payment Status</th>
              <th style="width: 35%; text-align: right;">Actions</th>
            </tr>
          </thead>
          <tbody v-if="records.length === 0 && !loadingPayoutRecords">
            <tr class="no-hover">
              <td colspan="7" class="text-center empty-table-message">
                <p><strong>You have no Unpaid payouts to show at this time</strong></p>
                <p class="mb-0 v2 pb-0">
                  <a href="https://help.syncio.co/en/articles/6398970-payouts-add-on-destination-store-side" target="_blank" class="link link-underline">
                    Learn more about payouts
                    <IconNewTab />
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="record in records" :key="record.payout_id" :class="{ 'selected': bulkUnpaidPayouts.includes(record.payout_id) }">
              <td>
                <UnpaidPayoutsCheckbox :payout="record" v-if="record.status !== 'cancelled'" />
              </td>
              <td>{{ record.date }}</td>
              <td>{{ record.payout_id }}</td>
              <td>{{ record.store_name }}</td>
              <td class="tabular-fonts"><span v-if="record.payout_total < 0">-$</span>{{ record.payout_total | currencyFormatter(currency) }}</td>
              <td>
                <span v-if="record.status === 'payout_created'" class="status unpaid">Unpaid</span>
                <span v-else class="status" :class="record.status">{{ record.status | removeUnderscore }}</span>
              </td>
              <td style="text-align: right;">
                <v-btn v-if="record.status !== 'cancelled'" elevation="0" class="btn btn-border btn-success" :disabled="isViewPayoutRecordLoading" @click="markAsPaidHandler(record.payout_id)">
                  <svg width="21" height="21" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1">
                    <path d="M9.49992 18.2501C9.39921 18.2467 9.30025 18.2228 9.20911 18.1798C9.11798 18.1368 9.03658 18.0757 8.96992 18.0001L3.96992 13.0001C3.87458 12.9383 3.79454 12.8556 3.73583 12.7584C3.67712 12.6611 3.64127 12.5517 3.631 12.4386C3.62073 12.3254 3.6363 12.2114 3.67654 12.1052C3.71677 11.9989 3.78062 11.9032 3.86326 11.8252C3.94591 11.7473 4.04518 11.6891 4.1536 11.6551C4.26201 11.6211 4.37673 11.6122 4.48909 11.6291C4.60145 11.6459 4.70852 11.6881 4.80221 11.7523C4.8959 11.8166 4.97376 11.9013 5.02992 12.0001L9.49992 16.4401L19.9699 6.00009C20.1109 5.9087 20.2784 5.86728 20.4457 5.88247C20.613 5.89765 20.7704 5.96857 20.8926 6.08385C21.0148 6.19913 21.0947 6.3521 21.1196 6.51823C21.1445 6.68437 21.1129 6.85405 21.0299 7.00009L10.0299 18.0001C9.96325 18.0757 9.88185 18.1368 9.79072 18.1798C9.69958 18.2228 9.60062 18.2467 9.49992 18.2501Z" fill="#fff"/>
                  </svg>
                  Mark as paid
                </v-btn>
                <v-btn elevation="0" class="btn btn-border ml-3" @click="viewPayoutHandler(record, 'destination')" :disabled="isViewPayoutRecordLoading">
                  <DocumentIcon /> <span class="ml-3">Manage Payout</span>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>

    <div class="pt-4 custom-pagination" v-if="records && recordsPagination.total > limiter">
      <v-pagination @input="paginationHandler($event)" v-model="recordsCurrentPage" class="my-4" :length="Math.ceil(recordsPagination.total / limiter)" total-visible="7" />
    </div>

    <Pagination :pagination="recordsPagination" @updateCurrentPage="updateCurrentPageHandler" class="v2 bt-0" />

    <!-- View Payout Dialog -->
    <ViewPayoutDialog v-if="isViewPayoutDialogVisible" />

    <!-- Order Details -->
    <v-dialog v-model="isOrderDetailsVisible" transition="slide-x-reverse-transition" light content-class="offscreen-dialog">
      <OrderDetailsBlock />
    </v-dialog>

  </section>
</template>
